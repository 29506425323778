import React, { Component } from 'react';
import "../App.css";

export default class Jshinm extends Component{
    render() {
        return (
            <div class="embed">
                <iframe src="https://jshinm.netlify.app/" title="embedded page" class='embed' />
            </div>
        )
    }
}